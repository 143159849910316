import { Button, Modal, TextInput } from '@mantine/core';
import React, { useState } from 'react';

import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface OrderReferenceIdModalProps
  extends ModalProps<{
    orderReferenceId: string;
    handleSave: (selectedOrderReferenceId: string) => void;
  }> {}

export function OrderReferenceIdModal({
  closeMe,
  data: { handleSave, orderReferenceId },
}: OrderReferenceIdModalProps) {
  const [referenceId, setReferenceId] = useState(orderReferenceId);

  const onSaveClick = () => {
    handleSave(referenceId);
    closeMe();
  };

  return (
    <Modal opened onClose={closeMe} title="Order reference ID" padding="xxl">
      <TextInput
        value={referenceId}
        onChange={(event) => setReferenceId(event.currentTarget.value)}
        placeholder="Enter name or ID for this order"
        data-testid="order-reference-id-input"
      />

      <ModalFooter position="right" pt="xxl">
        <Button onClick={closeMe} variant="default">
          Cancel
        </Button>
        <Button
          onClick={onSaveClick}
          data-testid="order-reference-id-save-button"
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
