import { createStyles, Group, Paper, Text } from '@mantine/core';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useStoreSettings,
  useUpdateStoreSettings,
} from '@portals/api/partners';
import {
  PolicyKey,
  useOpenPolicyModal,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as ArrowRight1 } from '@portals/icons/linear/arrow-right-1.svg';

export function Policies() {
  const { classes } = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const { isAdmin } = usePermissionAccess();
  const openPolicyModal = useOpenPolicyModal();
  const storeSettings = useStoreSettings();
  const updateStoreSettings = useUpdateStoreSettings();

  const onSubmitPolicy = useCallback(
    async (contentToSave: string, policyKey: PolicyKey) => {
      return await updateStoreSettings.mutateAsync({
        [policyKey]: contentToSave,
      });
    },
    [updateStoreSettings]
  );

  const onOpenPolicyModal = useCallback(
    (policyKey: PolicyKey) => {
      openPolicyModal({
        policyKey,
        editorContent: storeSettings.data?.[policyKey],
        isEditable: isAdmin,
        onSubmit: (contentToSave) => onSubmitPolicy(contentToSave, policyKey),
      });
    },
    [isAdmin, onSubmitPolicy, openPolicyModal, storeSettings.data]
  );

  useEffect(
    function openModalAccordingToSearchParams() {
      if (!storeSettings.isFetched) return;

      const searchParams = new URLSearchParams(location.search);
      const modalToOpen = searchParams.get('modal');

      if (!modalToOpen) return;

      if (modalToOpen === 'cancellations-policy') {
        onOpenPolicyModal('cancellations_and_returns_policy');
      }

      searchParams.delete('modal');

      navigate(
        {
          search: searchParams.toString(),
        },
        { replace: true }
      );
    },
    [navigate, location.search, onOpenPolicyModal, storeSettings.isFetched]
  );

  return (
    <Paper p="xl" withBorder c="blue_gray.9">
      <Text color="gray.8" mb="xl">
        Policies
      </Text>

      <Group
        position="apart"
        className={classes.policyRow}
        onClick={() => onOpenPolicyModal('terms_of_service')}
      >
        <Text>Terms of Service</Text>

        <ArrowRight1 />
      </Group>

      <Group
        position="apart"
        className={classes.policyRow}
        onClick={() => onOpenPolicyModal('privacy_policy')}
      >
        <Text>Privacy Policy</Text>

        <ArrowRight1 />
      </Group>

      <Group
        position="apart"
        className={classes.policyRow}
        onClick={() => onOpenPolicyModal('cancellations_and_returns_policy')}
      >
        <Text>Cancellations and Returns Policy</Text>

        <ArrowRight1 />
      </Group>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  policyRow: {
    paddingBlock: theme.spacing.xl,
    paddingInline: theme.spacing.xs,
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.colors.gray[3]}`,

    '&:last-of-type': {
      borderBottom: 'none',
    },

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },

    svg: {
      width: 15,
      height: 15,
      color: theme.colors.blue_gray[5],
    },
  },
}));
