import { Button, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  OfflinePaymentOrderItemType,
  useCreateOrder,
} from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { useConfirmationModal } from '@portals/framework';
import { PaymentIntervalEnum } from '@portals/types';
import { formatCurrency } from '@portals/utils';

import { CreateOrderNavigation } from './CreateOrderNavigation';
import { useCreateOrderWizard } from './hooks';
import { StepIdEnum } from './types';

export function WizardContent() {
  const { classes } = useStyles();

  const { isFinalStep, onNext, currentStep, onPrev, contextData } =
    useCreateOrderWizard();
  const createOrder = useCreateOrder();
  const asyncConfirmation = useConfirmationModal();

  const onSave = async () => {
    const isConfirmed = await asyncConfirmation({
      title: 'Please note',
      description: (
        <Stack spacing="xxl">
          <Text color="blue_gray.9">
            You are about to create an order for products with a value of{' '}
            <b>
              {formatCurrency(
                contextData.selectedStoreListingsTotalPrice,
                contextData.currency
              )}
            </b>{' '}
            which has been paid offline. Xyte fees will apply to this amount.
          </Text>
          <Text color="blue_gray.9" data-testid="confirmation-popup">
            Are you sure you want to proceed?
          </Text>
        </Stack>
      ),
      confirmButtonProps: { color: 'primary' },
    });

    if (isConfirmed) {
      const items: Array<OfflinePaymentOrderItemType> =
        contextData.selectedStoreListings.map((selectedStoreListing) => ({
          store_listing_id: selectedStoreListing.id,
          quantity:
            contextData.storeListingsQuantities[selectedStoreListing.id],
          selected_payment_interval: PaymentIntervalEnum.OneTime,
        }));

      createOrder.mutate(
        {
          items,
          note: contextData.orderNotes,
          referenceId: contextData.orderReferenceId,
          currency: contextData.currency,
          attachment: contextData.attachmentUrl,
        },
        { onSuccess: () => contextData.closeMe() }
      );
    }
  };

  const onNextClick = () => {
    onNext();

    if (isFinalStep) {
      onSave();
    }
  };

  const onBackClick = () => {
    if (isFinalStep) {
      onPrev();
    } else {
      contextData.closeMe();
    }
  };

  const isNextDisabled =
    currentStep.id === StepIdEnum.SelectProducts &&
    contextData.selectedStoreListings?.length === 0;

  return (
    <div className={classes.container}>
      <ModalBody className={classes.modalBody}>
        <CreateOrderNavigation />
        {currentStep.component}
      </ModalBody>

      <ModalFooter position="apart" py="xl">
        <Button variant="default" size="md" onClick={onBackClick}>
          {isFinalStep ? 'Back' : 'Cancel'}
        </Button>

        <Button
          size="md"
          onClick={onNextClick}
          disabled={isNextDisabled}
          data-testid="new-order-modal-next-button"
        >
          {isFinalStep ? 'Create order' : 'Next'}
        </Button>
      </ModalFooter>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },

  modalBody: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '220px 1fr',
    gap: 64,
    overflowY: 'hidden',
  },
}));
