import { Button, Group, Stack, Textarea, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { appendDump, useGetApiDevice } from '../../redux/actions/api';

export function AppendDumpFile() {
  const [dumpId, setDumpId] = useState('');
  const [dump, setDump] = useState('');

  const dispatch = useDispatch();
  const access = useGetApiDevice();

  return (
    <Stack>
      <TextInput
        placeholder="Dump ID"
        onChange={(event) => setDumpId(event.target.value)}
        value={dumpId}
      />

      <Textarea
        name="report"
        minRows={10}
        value={dump}
        onChange={(e) => setDump(e.target.value)}
      />

      <Group>
        <Button
          onClick={() =>
            dispatch(
              appendDump(access.id, dumpId, {
                dump,
              })
            )
          }
          disabled={!access.id || !dumpId}
        >
          Send
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id ? (
        <TestCommand
          url={`/${access.id}/dumps/${dumpId}`}
          data={{
            dump,
          }}
        />
      ) : null}

      <Response type="appendDump" />
    </Stack>
  );
}
