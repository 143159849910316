import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useCurrentUser } from '@portals/api/ui';
import { XyteConnectIcon } from '@portals/framework';
import { ReactComponent as BarcodeIcon } from '@portals/icons/linear/barcode.svg';
import { ReactComponent as BoxIcon } from '@portals/icons/linear/box.svg';
import { ReactComponent as BrushIcon } from '@portals/icons/linear/brush.svg';
import { ReactComponent as CodeIcon } from '@portals/icons/linear/code.svg';
import { ReactComponent as CpuIcon } from '@portals/icons/linear/cpu.svg';
import { ReactComponent as DangerIcon } from '@portals/icons/linear/danger.svg';
import { ReactComponent as DollarCircleIcon } from '@portals/icons/linear/dollar-circle.svg';
import { ReactComponent as Element4Icon } from '@portals/icons/linear/element-4.svg';
import { ReactComponent as Home3Icon } from '@portals/icons/linear/home-3.svg';
import { ReactComponent as MonitorMobileIcon } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Receipt1Icon } from '@portals/icons/linear/receipt-1.svg';
import { ReactComponent as ShopIcon } from '@portals/icons/linear/shop.svg';
import { ReactComponent as Tag2Icon } from '@portals/icons/linear/tag-2.svg';
import { ReactComponent as TaskSquareIcon } from '@portals/icons/linear/task-square.svg';
import { ReactComponent as TaskIcon } from '@portals/icons/linear/task.svg';
import { ReactComponent as TicketIcon } from '@portals/icons/linear/ticket.svg';
import { ReactComponent as TruckFastIcon } from '@portals/icons/linear/truck-fast.svg';
import { ReactComponent as UserTagIcon } from '@portals/icons/linear/user-tag.svg';

import { Hub, HubSidebarItem } from './hubs.types';
import { DASHBOARD_PATHS } from '../routes/dashboard/dashboard-paths.constants';
import { useDashboardRoutes } from '../routes/dashboard/DashboardRoutesProvider';

interface HubsContextType {
  hubs: Array<Hub>;
  activeHub: Hub | undefined;
  isSidebarItemActive: (sidebarItem: HubSidebarItem) => boolean;
}

const HubsContext = createContext<HubsContextType | null>(null);

export function HubsProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  const currentUser = useCurrentUser();

  const { canAccessPath } = useDashboardRoutes();

  const allHubs = useMemo(() => {
    const allHubs: Array<Hub> = [
      {
        id: 'home',
        header: <Home3Icon />,
        sidebarTitle: `Welcome, ${currentUser.data?.name}`,
        sidebarItems: [
          {
            id: 'get-started',
            label: 'Get Started',
            path: DASHBOARD_PATHS.staticPaths.getStarted,
            icon: <TaskIcon />,
          },
          {
            id: 'dashboard-overview',
            label: 'Overview',
            path: DASHBOARD_PATHS.staticPaths.dashboardOverview,
            icon: <Element4Icon />,
          },
          {
            id: 'xyteConnect',
            label: 'Xyte Connect',
            icon: <XyteConnectIcon />,
            subItems: [
              {
                label: 'Customers',
                path: DASHBOARD_PATHS.staticPaths.customers,
              },
              {
                label: 'Partners',
                path: DASHBOARD_PATHS.staticPaths.partners,
              },
            ],
          },
        ],
      },
      {
        id: 'product',
        header: 'Product',
        sidebarTitle: 'Product Hub',
        sidebarItems: [
          {
            id: 'models',
            label: 'Models',
            path: DASHBOARD_PATHS.staticPaths.deviceModels,
            icon: <BarcodeIcon />,
          },
          {
            id: 'devices',
            label: 'Devices',
            path: DASHBOARD_PATHS.staticPaths.devices,
            icon: <MonitorMobileIcon />,
          },
          {
            id: 'files',
            label: 'Files',
            path: DASHBOARD_PATHS.staticPaths.files,
            icon: <CpuIcon />,
          },
          {
            id: 'dev-center',
            label: 'Dev Center',
            path: DASHBOARD_PATHS.staticPaths.devCenter,
            icon: <CodeIcon />,
          },
        ],
      },
      {
        id: 'ecommerce',
        header: 'eCommerce',
        sidebarTitle: 'eCommerce Hub',
        sidebarItems: [
          {
            id: 'product-catalog',
            label: 'Product Catalog',
            path: DASHBOARD_PATHS.staticPaths.productCatalog,
            icon: <BoxIcon />,
          },
          {
            id: 'product-listings',
            label: 'Product Listings',
            path: DASHBOARD_PATHS.staticPaths.storeListings,
            icon: <Tag2Icon />,
          },
          {
            id: 'storeManagement',
            label: 'Store Management',
            icon: <ShopIcon />,
            subItems: [
              {
                label: 'Orders',
                path: DASHBOARD_PATHS.staticPaths.orders,
              },
              {
                label: 'Subscriptions',
                path: DASHBOARD_PATHS.staticPaths.subscriptions,
              },
              {
                label: 'Sold Products',
                path: DASHBOARD_PATHS.staticPaths.soldProducts,
              },
            ],
          },
        ],
      },
      {
        id: 'operations',
        header: 'Operations',
        sidebarTitle: 'Operations Hub',
        sidebarItems: [
          {
            id: 'shipping',
            label: 'Shipping',
            path: DASHBOARD_PATHS.staticPaths.shipping,
            icon: <TruckFastIcon />,
          },
        ],
      },
      {
        id: 'finance',
        header: 'Finance',
        sidebarTitle: 'Finance Hub',
        sidebarItems: [
          {
            id: 'credit-providers',
            label: 'Credit Providers',
            path: DASHBOARD_PATHS.staticPaths.creditProviders,
            icon: <DollarCircleIcon />,
          },
          {
            id: 'invoices',
            label: 'Invoices',
            path: DASHBOARD_PATHS.staticPaths.invoices,
            icon: <Receipt1Icon />,
          },
          {
            id: 'orders',
            label: 'Orders',
            path: DASHBOARD_PATHS.staticPaths.financeOrders,
            icon: <TaskSquareIcon />,
          },
        ],
      },
      {
        id: 'support',
        header: 'Support',
        sidebarTitle: 'Support Hub',
        sidebarItems: [
          {
            id: 'support-overview',
            label: 'Overview',
            path: DASHBOARD_PATHS.staticPaths.supportOverview,
            icon: <Element4Icon />,
          },
          {
            id: 'tickets',
            label: 'Tickets',
            path: DASHBOARD_PATHS.staticPaths.tickets,
            icon: <TicketIcon />,
          },
          {
            id: 'incidents',
            label: 'Incidents',
            path: DASHBOARD_PATHS.staticPaths.incidents,
            icon: <DangerIcon />,
          },
          {
            id: 'supported-customers',
            label: 'Supported Customers',
            path: DASHBOARD_PATHS.staticPaths.supportedCustomers,
            icon: <UserTagIcon />,
          },
        ],
      },
      {
        id: 'marketing',
        header: 'Marketing',
        sidebarTitle: 'Marketing Hub',
        sidebarItems: [
          {
            id: 'branding',
            label: 'Branding',
            path: DASHBOARD_PATHS.staticPaths.branding,
            icon: <BrushIcon data-testid="marketing-tab" />,
          },
        ],
      },
    ];

    return allHubs;
  }, [currentUser.data?.name]);

  const availableHubs = useMemo(() => {
    const result: Hub[] = [];

    allHubs.forEach((hub) => {
      const newHub = { ...hub };

      newHub.sidebarItems = newHub.sidebarItems.filter((sidebarItem) => {
        if (sidebarItem.subItems) {
          sidebarItem.subItems = sidebarItem.subItems.filter((subItem) =>
            canAccessPath(subItem.path)
          );

          return sidebarItem.subItems.length > 0;
        }

        return canAccessPath(sidebarItem.path);
      });

      if (newHub.sidebarItems.length > 0) {
        result.push(newHub);
      }
    });

    return result;
  }, [canAccessPath, allHubs]);

  // This is a reference that holds the most recently active hub.
  // It defaults to the first available hub.
  // This is used to maintain a non-empty sidebar and header when
  // the current route does not correspond to any specific hub.
  const lastActiveHub = useRef<Hub>(availableHubs[0]);

  const isSidebarItemActive = useCallback(
    (sidebarItem: HubSidebarItem) => {
      if (sidebarItem.subItems) {
        return sidebarItem.subItems.some((subItem) =>
          matchPath({ path: subItem.path, end: false }, location.pathname)
        );
      } else {
        return Boolean(
          matchPath({ path: sidebarItem.path, end: false }, location.pathname)
        );
      }
    },
    [location.pathname]
  );

  const activeHub = useMemo<Hub>(() => {
    const currentActiveHub = availableHubs.find((hub) =>
      hub.sidebarItems.some((sidebarItem) => isSidebarItemActive(sidebarItem))
    );

    if (!currentActiveHub) {
      return lastActiveHub.current;
    }

    lastActiveHub.current = currentActiveHub;
    return currentActiveHub;
  }, [availableHubs, isSidebarItemActive]);

  return (
    <HubsContext.Provider
      value={{
        hubs: availableHubs,
        activeHub,
        isSidebarItemActive,
      }}
    >
      {children}
    </HubsContext.Provider>
  );
}

export function useHubs() {
  const context = useContext(HubsContext);

  if (!context) {
    throw new Error('useHubs must be used within a HubsProvider');
  }

  return context;
}
