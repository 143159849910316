import { Button, Group, Stack } from '@mantine/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { closeIncidents, useGetApiDevice } from '../../redux/actions/api';

export function CloseIncidents() {
  const dispatch = useDispatch();
  const access = useGetApiDevice();

  return (
    <Stack>
      <Group>
        <Button
          onClick={() => dispatch(closeIncidents(access.id))}
          disabled={!access.id}
        >
          Delete
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id && (
        <TestCommand
          url={`/${access.id}/incidents`}
          method="DELETE"
          data={{
            id: access.id,
          }}
        />
      )}

      <Response type="closeIncidents" />
    </Stack>
  );
}
