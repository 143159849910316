import { IntegrationsRouteModal } from '@portals/framework';
import { PartnerConfigurationType, RoutesMap } from '@portals/types';

import { CreditProviders as CreditProvidersRouteModal } from './route-modals/CreditProviders';
import { Customer } from './route-modals/Customer';
import { OfflineOrder } from './route-modals/offline-order/OfflineOrder';
import { Order } from './route-modals/Order/Order';
import { PurchasedProductRoutePanel } from './route-panels/product/PurchasedProductRoutePanel';
import { StoreListingRoutePanel } from './route-panels/store-listings/StoreListingRoutePanel';
import { SubscriptionRoutePanel } from './route-panels/subscriptions/SubscriptionRoutePanel';

const routeModals: RoutesMap<PartnerConfigurationType>['routeModals'] = [
  {
    id: 'customer',
    path: '/m/customers/:customer_id',
    component: Customer,
    backgroundPath: '/customers',
    generatePath: ([customerId]: [string]) => `/m/customers/${customerId}`,
  },
  {
    id: 'order',
    path: '/m/order/:order_id',
    component: Order,
    backgroundPath: '/store-management/orders',
    generatePath: ([orderId]: [string]) => `/m/order/${orderId}`,
  },
  {
    id: 'offline-order',
    path: '/m/offline-order/:order_id',
    component: OfflineOrder,
    backgroundPath: '/store-management/orders',
    generatePath: ([orderId]: [string]) => `/m/offline-order/${orderId}`,
  },
  {
    id: 'credit-providers',
    path: '/m/credit-providers',
    component: CreditProvidersRouteModal,
    backgroundPath: '/',
    generatePath: () => `/m/credit-providers`,
  },
  {
    id: 'integrations',
    path: ['/m/integrations', '/m/integrations/:integration'],
    component: IntegrationsRouteModal,
    backgroundPath: '/integrations',
    canAccessRoute: ({ configuration }) =>
      Boolean(configuration?.feature_flags?.integrations_demo),
    generatePath: () => `/m/integrations`,
  },
];

const routePanels: RoutesMap<PartnerConfigurationType>['routePanels'] = [
  {
    id: 'store_listing',
    path: '/p/products/:store_listing_slug',
    component: StoreListingRoutePanel,
    backgroundPath: '/products',
    generatePath: ([store_listing_slug]: string[]) =>
      `/p/products/${store_listing_slug}`,
  },
  {
    id: 'subscription',
    path: '/p/subscription/:subscription_id',
    component: SubscriptionRoutePanel,
    backgroundPath: '/store-management/subscriptions',
    generatePath: ([subscriptionId]: string[]) =>
      `/p/subscription/${subscriptionId}`,
  },
  {
    id: 'purchased_product',
    path: '/p/purchased_product/:purchased_product_id',
    component: PurchasedProductRoutePanel,
    backgroundPath: '/store-management/purchased-products',
    generatePath: ([purchasedProductId]: string[]) =>
      `/p/purchased_product/${purchasedProductId}`,
  },
];

export const routes: RoutesMap<PartnerConfigurationType> = {
  dashboard: [],
  routeModals,
  routePanels,
};
