import {
  createStyles,
  Group,
  HoverCard,
  HoverCardProps,
  Text,
} from '@mantine/core';
import React, { useMemo } from 'react';

import { useCecPartnersAndIntegrations } from '@portals/api/partners';
import { C2C_INTEGRATIONS, PartnerAvatar } from '@portals/framework';
import { ReactComponent as InfoCircleIcon } from '@portals/icons/linear/info-circle.svg';

interface CecPartnersAndIntegrationsListProps {
  organizationId: string;
}

export function CecPartnersAndIntegrationsList({
  organizationId,
}: CecPartnersAndIntegrationsListProps) {
  const { classes } = useStyles();

  const cecPartnersAndIntegrations =
    useCecPartnersAndIntegrations(organizationId);

  const integrations = useMemo(
    () =>
      cecPartnersAndIntegrations.data?.integrations?.map(
        (integrationId) => C2C_INTEGRATIONS[integrationId]
      ),
    [cecPartnersAndIntegrations.data?.integrations]
  );

  if (!cecPartnersAndIntegrations.data?.partners && !integrations) {
    return null;
  }

  return (
    <HoverCard withinPortal position="bottom" styles={hoverCardStyles}>
      <HoverCard.Target data-testid="brands-tooltip">
        <InfoCircleIcon className={classes.infoIcon} />
      </HoverCard.Target>

      <HoverCard.Dropdown mah={320} miw={260}>
        <div>
          {cecPartnersAndIntegrations.data?.partners?.map((cecPartner) => (
            <Group noWrap spacing="md" key={cecPartner.id} p="xs">
              <PartnerAvatar
                src={cecPartner.logo}
                partnerName={cecPartner.name}
                size={52}
              />
              <Text
                weight={600}
                color="blue_gray.9"
                data-testid="connected-brand-name"
              >
                {cecPartner.name}
              </Text>
            </Group>
          ))}

          {integrations?.map((integration) => (
            <Group noWrap spacing="md" key={integration.id} p="xs">
              <PartnerAvatar
                src={integration.logo}
                partnerName={integration.name}
                size={52}
              />
              <Text weight={600} color="blue_gray.9">
                {integration.name}
              </Text>
            </Group>
          ))}
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const hoverCardStyles: HoverCardProps['styles'] = (theme) => ({
  dropdown: {
    overflowY: 'auto',
    borderRadius: theme.radius.lg,
    padding: theme.spacing.md,
    boxShadow: '0px 11px 18px 0px rgba(38, 50, 56, 0.18)',
  },
});

const useStyles = createStyles((theme) => ({
  infoIcon: {
    width: 18,
    height: 18,
    color: theme.colors.gray[5],
    cursor: 'pointer',

    '&:hover': {
      color: theme.colors.gray[9],
    },
  },
}));
