import { Stack, Button, Text, Group } from '@mantine/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { JsonEditor } from '@portals/ui';
import { isJsonString } from '@portals/utils';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getApiDevice, updateCommand } from '../../redux/actions/api';

const COMMAND_BASE = `{
  "id": "COMMAND_ID",
  "status": "done",
  "message": "great success!"
}`;

const SetCommand = ({ access, updateCommand }) => {
  const [command, setCommand] = useState(COMMAND_BASE);
  const validCommand = isJsonString(command);

  return (
    <Stack>
      <JsonEditor onChange={setCommand} data={command} size={10} />

      {!validCommand && <Text color="red">Invalid json</Text>}

      <Group>
        <Button
          disabled={!access.id || !validCommand}
          onClick={() => updateCommand(access.id, JSON.parse(command))}
        >
          Send
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id && validCommand && (
        <TestCommand url={`/${access.id}/command`} data={JSON.parse(command)} />
      )}
      <Response type="updateCommand" />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { updateCommand })(SetCommand);
