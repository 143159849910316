import { Box, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { OrderType } from '@portals/api/partners';
import { useGenericContext } from '@portals/framework';
import {
  PartnerOrderFulfillmentStatusBadge,
  PartnerOrderPaymentStatusBadge,
} from '@portals/framework/route-modals';

export function OrderHeader() {
  const { classes } = useStyles();
  const order = useGenericContext<OrderType>();

  if (!order) {
    return null;
  }

  return (
    <Box className={classes.wrapper}>
      <Group align="center">
        <Text color="blue_gray.8" size={28} fw={500}>
          {order?.short_id}
        </Text>

        <PartnerOrderPaymentStatusBadge order={order} />

        <PartnerOrderFulfillmentStatusBadge order={order} />
      </Group>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    padding: `${theme.spacing.md} ${theme.spacing.xxl}`,
    width: '100%',
  },
}));
