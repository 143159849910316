import { Stack, Button, Text, Group } from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { JsonEditor } from '@portals/ui';
import { isJsonString } from '@portals/utils';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { addEvent, useGetApiDevice } from '../../redux/actions/api';

const EVENT_BASE = `{
  "name": "try_out",
  "category": "printer_manual_actions",
  "int_key_1": 10,
  "int_key_2": 20,
  "string_key_1": "",
  "string_key_2": "",
  "timestamp": "2024-08-15T12:34:56.789Z"
}`;

export function AddEvent() {
  const [event, setEvent] = useState(EVENT_BASE);
  const validCommand = isJsonString(event);

  const dispatch = useDispatch();
  const access = useGetApiDevice();

  return (
    <Stack>
      <JsonEditor onChange={setEvent} data={event} size={15} />

      {!validCommand && <Text color="red">Invalid json</Text>}

      <Group>
        <Button
          disabled={!access.id || !validCommand}
          onClick={() => dispatch(addEvent(access.id, JSON.parse(event)))}
        >
          Add
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id && validCommand && (
        <TestCommand url={`/${access.id}/events`} data={JSON.parse(event)} />
      )}
      <Response type="addEvent" />
    </Stack>
  );
}
