import { Button, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { JsonEditor } from '@portals/ui';
import { isJsonString } from '@portals/utils';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getApiDevice, sendChildTelemetries } from '../../redux/actions/api';

const TELEMETRY_BASE = `{
  "{{CHILD_DEVICE_ID}}": {
    "status": "online",
    "telemetries": {
      "speed": 10,
      "fuel": 99
    }
  },
  "{{OTHER_CHILD_DEVICE_ID}}": {
    "status": "offline"
  }
}`;

export function SendChildTelemetries() {
  const access = useSelector(getApiDevice);
  const dispatch = useDispatch();

  const [telemetry, setTelemetry] = useState(TELEMETRY_BASE);
  const validTelemetry = isJsonString(telemetry);

  return (
    <Stack data-testid="send-telemetry-stack">
      <JsonEditor onChange={setTelemetry} data={telemetry} />

      {!validTelemetry && <Text color="red">Invalid json</Text>}

      <Group>
        <Button
          disabled={!access.id || !validTelemetry}
          data-testid="send-child-telemetries-button"
          onClick={() =>
            dispatch(sendChildTelemetries(access.id, JSON.parse(telemetry)))
          }
        >
          Send
        </Button>

        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id && validTelemetry && (
        <TestCommand
          url={`/${access.id}/children/telemetries`}
          data={JSON.parse(telemetry)}
        />
      )}

      <Response type="sendChildTelemetries" />
    </Stack>
  );
}
