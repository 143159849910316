import { isEmpty, keys, size } from 'lodash/fp';
import React, { useMemo } from 'react';
import { AutoSizer } from 'react-virtualized';
import { Table } from 'reactstrap';
import { Bar, BarChart, CartesianGrid, Tooltip, YAxis } from 'recharts';
import styled from 'styled-components';

import { EmptyState } from '@portals/table';
import { useTheme } from '@portals/ui';
import {
  abbreviateNumber,
  getStyledThemeColor,
  hexToRgba,
} from '@portals/utils';

import { StatCardContainer } from './common';

const DevicesByModel = ({ models, offline }) => {
  const { color } = useTheme();

  const colors = useMemo(
    () =>
      keys(models).map((_, index) =>
        hexToRgba(color.brown, 100 - (60 / size(keys(models))) * index)
      ),
    [color.brown, models]
  );

  if (isEmpty(models)) {
    return (
      <Container>
        <div className="header">
          <div className="title">Registered Devices Per Model</div>
        </div>

        <EmptyState
          label="No available devices data"
          src="empty-state-widgets"
        />
      </Container>
    );
  }

  return (
    <Container>
      <div className="header">
        <div className="title">Registered Devices Per Model</div>
      </div>

      <div className="chart-wrapper">
        <AutoSizer>
          {({ height, width }) => (
            <BarChart
              width={width}
              height={height}
              data={[models]}
              barSize={20}
              barGap={20}
            >
              <CartesianGrid vertical={false} stroke={color.gray300} />

              <YAxis
                tickSize={0}
                tickMargin={20}
                axisLine={false}
                tickFormatter={abbreviateNumber}
              />

              <Tooltip
                cursor={false}
                labelFormatter={() => 'Connected Devices'}
                wrapperStyle={{ backgroundColor: 'red' }}
              />

              {keys(models).map((model, index) => (
                <Bar
                  key={model}
                  dataKey={model}
                  barSize={20}
                  minPointSize={-1}
                  radius={[4, 4, 0, 0]}
                  fill={colors[index]}
                />
              ))}
            </BarChart>
          )}
        </AutoSizer>
      </div>

      <Table className="mb-0">
        <thead>
          <tr>
            <th>Model</th>
            <th className="text-center">Connected Devices</th>
            <th className="text-right">Offline Devices</th>
          </tr>
        </thead>
        <tbody>
          {keys(models).map((name, index) => (
            <tr key={name}>
              <td>
                <div
                  className="legend-circle"
                  style={{ background: colors[index] }}
                />

                {name}
              </td>
              <td className="text-center">{models[name]}</td>
              <td className="text-right">{offline[name]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled(StatCardContainer)`
  grid-template-rows: max-content 200px 1fr;
  grid-row-gap: 35px;

  .chart-wrapper {
    height: 200px;

    .recharts-bar-rectangle {
      path {
        filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});
      }
    }
  }

  table {
    height: fit-content;

    tr {
      td {
        .legend-circle {
          display: inline-block;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
`;

export default DevicesByModel;
