import { Button, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { JsonEditor } from '@portals/ui';
import { isJsonString } from '@portals/utils';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { setCloudSettings, useGetApiDevice } from '../../redux/actions/api';

const DEVICE_INFO_BASE = `{
  "property": "incidents.suspend_creation",
  "value": 60
}`;

export function SetCloudSettings() {
  const [deviceInfo, setDeviceInfo] = useState(DEVICE_INFO_BASE);
  const validDeviceInfo = isJsonString(deviceInfo);

  const dispatch = useDispatch();
  const access = useGetApiDevice();

  return (
    <Stack data-testid="set-cloud-settings">
      <JsonEditor onChange={setDeviceInfo} data={deviceInfo} size={10} />

      {!validDeviceInfo && <Text color="red">Invalid json</Text>}

      <Group>
        <Button
          disabled={!access.id}
          onClick={() =>
            dispatch(setCloudSettings(access.id, JSON.parse(deviceInfo)))
          }
        >
          Send
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id && (
        <TestCommand
          url={`/${access.id}`}
          data={{
            id: access.id,
          }}
          method="PUT"
        />
      )}

      <Response type="setCloudSettings" />
    </Stack>
  );
}
