import {
  Box,
  createStyles,
  Grid,
  Group,
  NavLink,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode, useRef, useState } from 'react';

import { DashboardContentLayout } from '@portals/framework';
import { ReactComponent as List } from '@portals/icons/linear/list.svg';

import { AddEvent } from './AddEvent';
import { AppendDumpFile } from './AppendDumpFile';
import { CloseIncident } from './CloseIncident';
import { CloseIncidents } from './CloseIncidents';
import { DeleteDevice } from './DeleteDevice';
import { DevCenterSection } from './DeviceCenterSection';
import DeviceSelector from './DeviceSelector';
import { GetChildDevices } from './GetChildDevices';
import GetCommand from './GetCommand';
import GetConfig from './GetConfig';
import GetDevice from './GetDevice';
import { GetFile } from './GetFile';
import { GetFiles } from './GetFiles';
import { GetIncidents } from './GetIncidents';
import GetLicense from './GetLicense';
import { GetLicenses } from './GetLicenses';
import { GetSpace } from './GetSpace';
import { OpenIncident } from './OpenIncident';
import { Register } from './register/Register';
import { SendChildTelemetries } from './SendChildTelemtries';
import SendDump from './SendDump';
import SendTelemetry from './SendTelemetry';
import { SetCloudSettings } from './SetCloudSettings';
import SetConfig from './SetConfig';
import UpdateCommand from './UpdateCommand';
import UpdateDevice from './UpdateDevice';
import UpdateLicense from './UpdateLicense';

interface SectionItem {
  id: string;
  title: string;
  subtitle: string;
  docsLink?: string;
  component: ReactNode;
}

const SECTIONS: SectionItem[] = [
  {
    id: 'register-device',
    title: 'Register Device',
    subtitle: 'Device registration API',
    docsLink: 'https://dev.xyte.io/reference/register-device',
    component: <Register />,
  },
  {
    id: 'set-managed-device',
    title: 'Set managed device',
    subtitle:
      'Set the device ID and access key to be used in all following APIs',
    component: <DeviceSelector />,
  },
  {
    id: 'get-child-devices',
    title: 'Get Child Devices',
    subtitle: 'Gets a list of all child devices of a parent device.',
    docsLink: 'https://dev.xyte.io/reference/get-child-devices',
    component: <GetChildDevices />,
  },
  {
    id: 'send-telemetry',
    title: 'Send Telemetry',
    subtitle: 'Send telemetry data',
    docsLink: 'https://dev.xyte.io/reference/send-telemetry',
    component: <SendTelemetry />,
  },
  {
    id: 'send-child-telemetries',
    title: 'Send Child Telemetries',
    subtitle:
      'This API allows a Parent device to send telemetry status for a number of children in one API request.',
    docsLink: 'https://dev.xyte.io/reference/send-child-telemetries',
    component: <SendChildTelemetries />,
  },
  {
    id: 'get-command',
    title: 'Get Command',
    subtitle: 'Get details of pending command',
    docsLink: 'https://dev.xyte.io/reference/get-command',
    component: <GetCommand />,
  },
  {
    id: 'update-command',
    title: 'Update Command',
    subtitle: 'Update server on command execution status',
    docsLink: 'https://dev.xyte.io/reference/update-command',
    component: <UpdateCommand />,
  },
  {
    id: 'update-device',
    title: 'Update Device',
    subtitle: 'Update device information',
    docsLink: 'https://dev.xyte.io/reference/update-device',
    component: <UpdateDevice />,
  },
  {
    id: 'set-cloud-settings',
    title: 'Set Cloud Settings',
    subtitle:
      'Enables the ability to set up different cloud settings for a given device',
    docsLink: 'https://dev.xyte.io/reference/set-cloud-settings',
    component: <SetCloudSettings />,
  },
  {
    id: 'get-device-info',
    title: 'Get Device Info',
    subtitle: 'Fetch device configuration from server',
    docsLink: 'https://dev.xyte.io/reference/get-device-info',
    component: <GetDevice />,
  },
  {
    id: 'get-space-info',
    title: 'Get Space Info',
    subtitle: 'Get the latest device space information from the server',
    docsLink: 'https://dev.xyte.io/reference/get-space-info',
    component: <GetSpace />,
  },
  {
    id: 'get-config',
    title: 'Get Config',
    subtitle: 'Get latest device configuration from the server',
    docsLink: 'https://dev.xyte.io/reference/get-config',
    component: <GetConfig />,
  },
  {
    id: 'set-config',
    title: 'Set Config',
    subtitle: 'Send updated configuration to server',
    docsLink: 'https://dev.xyte.io/reference/set-config',
    component: <SetConfig />,
  },
  {
    id: 'send-dump',
    title: 'Send Dump',
    subtitle: 'Send a detailed report of device internals to the server',
    docsLink: 'https://dev.xyte.io/reference/send-dump',
    component: <SendDump />,
  },
  {
    id: 'append-dump-file',
    title: 'Append Dump File',
    subtitle:
      'Append the sent data to the end of an existing dump and returns the new total length (number of characters)',
    docsLink: 'https://dev.xyte.io/reference/append-dump-file',
    component: <AppendDumpFile />,
  },
  {
    id: 'get-license',
    title: 'Get License',
    subtitle: 'Get pending license changed from the server',
    docsLink: 'https://dev.xyte.io/reference/get-license',
    component: <GetLicense />,
  },
  {
    id: 'get-licenses',
    title: 'Get Licenses',
    subtitle: 'Get pending licenses changed from the server',
    docsLink: 'https://dev.xyte.io/reference/get-licenses',
    component: <GetLicenses />,
  },
  {
    id: 'update-license',
    title: 'Update License',
    subtitle: 'Update server on license state',
    docsLink: 'https://dev.xyte.io/reference/update-license',
    component: <UpdateLicense />,
  },
  {
    id: 'get-file',
    title: 'Get File',
    subtitle:
      'Get information about a single file attached to its model by the manufacturer.',
    docsLink: 'https://dev.xyte.io/reference/get-file',
    component: <GetFile />,
  },
  {
    id: 'get-files',
    title: 'Get Files',
    subtitle:
      'Get information about all the files attached to device model from the server',
    docsLink: 'https://dev.xyte.io/reference/get-files',
    component: <GetFiles />,
  },
  {
    id: 'close-incident',
    title: 'Close Incident',
    subtitle: 'Close an incident created via the Open Incident API.',
    docsLink: 'https://dev.xyte.io/reference/close-incident',
    component: <CloseIncident />,
  },
  {
    id: 'close-incidents',
    title: 'Close Incidents',
    subtitle: 'Close all open incidents created via the Open Incident API.',
    docsLink: 'https://dev.xyte.io/reference/close-incidents',
    component: <CloseIncidents />,
  },
  {
    id: 'get-incidents',
    title: 'Get Incidents',
    subtitle: 'Get All incidents of the specific device from the server',
    docsLink: 'https://dev.xyte.io/reference/get-incident',
    component: <GetIncidents />,
  },
  {
    id: 'delete-device',
    title: 'Delete Device',
    subtitle: 'Delete a device and all its historical data from the platform.',
    docsLink: 'https://dev.xyte.io/reference/delete-device',
    component: <DeleteDevice />,
  },
  {
    id: 'open-incident',
    title: 'Open Incident',
    subtitle:
      'Open custom incident based on detected errors or internal state.',
    docsLink: 'https://dev.xyte.io/reference/create-incident',
    component: <OpenIncident />,
  },
  {
    id: 'add-event',
    title: 'Add Event',
    subtitle: 'Add a new event to the Device Events Log.',
    docsLink: 'https://dev.xyte.io/reference/add-event',
    component: <AddEvent />,
  },
];

export function DevCenter() {
  const { classes } = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const [activeSection, setActiveSection] = useState('');

  return (
    <DashboardContentLayout
      pageTitle="Dev Center"
      subtitle="Developer Test Tool"
    >
      <Grid className={classes.gridContainer} ref={containerRef}>
        <Grid.Col span={9}>
          <Stack spacing={32}>
            {SECTIONS.map(
              ({ id, title, subtitle, docsLink, component }, index) => (
                <DevCenterSection
                  key={id}
                  ref={(el: HTMLDivElement) =>
                    (sectionRefs.current[index] = el)
                  }
                  id={id}
                  title={title}
                  subtitle={subtitle}
                  docsLink={docsLink}
                >
                  {component}
                </DevCenterSection>
              )
            )}
          </Stack>
        </Grid.Col>

        <Grid.Col span={3}>
          <Box className={classes.navigationContainer}>
            <Group>
              <List width={16} height={16} />
              <Text>Table of contents</Text>
            </Group>
            {SECTIONS.map(({ id, title }, index) => (
              <NavLink
                key={id}
                active={id === activeSection}
                label={title}
                onClick={() => {
                  setActiveSection(id);
                  sectionRefs.current[index].scrollIntoView({
                    behavior: 'smooth',
                  });
                }}
              />
            ))}
          </Box>
        </Grid.Col>
      </Grid>
    </DashboardContentLayout>
  );
}

const useStyles = createStyles(() => ({
  gridContainer: {
    maxWidth: 1000,
  },
  navigationContainer: {
    position: 'sticky',
    top: -30,
  },
}));
