import { Box, createStyles, ScrollArea } from '@mantine/core';
import React, { useState } from 'react';

import {
  OrderType,
  useUpdateAttachment,
  useUpdateNote,
  useUpdateReferenceId,
} from '@portals/api/partners';
import { useGenericContext } from '@portals/framework';

import { OrderItemsList } from './OrderItemsList';
import { OrderGeneralInfo } from '../../components/offline-payment-order-general-info/OrderGeneralInfo';

export function OrderContent() {
  const { classes } = useStyles();

  const order = useGenericContext<OrderType>();

  const updateReferenceId = useUpdateReferenceId();
  const updateNote = useUpdateNote();
  const updateAttachment = useUpdateAttachment();

  const [referenceId, setReferenceId] = useState(order?.reference_id);
  const [note, setNote] = useState(order?.note);
  const [attachmentUrl, setAttachmentUrl] = useState(
    order?.attachments?.[0].url
  );

  if (!order) {
    return null;
  }

  const onReferenceIdChange = (referenceId: string) => {
    setReferenceId(referenceId);
    updateReferenceId.mutate({ id: order.id, referenceId });
  };

  const onNoteChange = (note: string) => {
    setNote(note);
    updateNote.mutate({ id: order.id, note });
  };

  const onAttachmentUrlChange = (attachmentUrl: string) => {
    setAttachmentUrl(attachmentUrl);
    updateAttachment.mutate({ id: order.id, attachment: attachmentUrl });
  };

  return (
    <ScrollArea
      styles={{ viewport: { '> div': { height: '100%' } } }}
      type="auto"
    >
      <Box className={classes.wrapper} py="xxl">
        <div className={classes.grid}>
          <OrderItemsList order={order} />
          <OrderGeneralInfo
            currency={order?.currency}
            itemsTotalPrice={order.total_amount_in_scu}
            referenceId={referenceId}
            setReferenceId={onReferenceIdChange}
            notes={note}
            setNotes={onNoteChange}
            attachmentUrl={attachmentUrl}
            setAttachmentUrl={onAttachmentUrlChange}
          />
        </div>
      </Box>
    </ScrollArea>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'grid',
    background: theme.white,
    width: '100%',
    height: '100%',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    backgroundColor: theme.colors.gray[0],
    position: 'relative',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: theme.spacing.xl,
    width: '90%',
    margin: 'auto',
  },
}));
