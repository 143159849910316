import { Anchor, Text, Tooltip } from '@mantine/core';
import { capitalize, join, last, replace, split, startsWith } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  INCIDENTS_API_URL,
  PartnerIncidentType,
  useIsChannelPartner,
  usePaginatedIncidents,
} from '@portals/api/partners';
import { DashboardContentLayout, PriorityIcon } from '@portals/framework';
import { AvatarCell, DateCell, PaginatedTable } from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  TableColumn,
  TableColumnFormatter,
  TableFilterTypeEnum,
} from '@portals/types';
import { PRIORITY_OPTIONS } from '@portals/utils';

import { IncidentDetailsPanel } from './IncidentDetailsPanel';
import { IncludeLabCustomersToggle } from '../../components/IncludeLabCustomersToggle';
import { useSupportState } from '../support-overview/SupportStateProvider';

const spaceFormatter: TableColumnFormatter<PartnerIncidentType> = (
  _,
  { space_path }
) => {
  const spaceName = last(split('/', space_path));
  const fullPath = join(' / ', split('/', space_path));

  return (
    <Tooltip label={fullPath}>
      <Text inherit>{spaceName}</Text>
    </Tooltip>
  );
};

function IncidentsList() {
  const isChannelPartner = useIsChannelPartner();
  const { isLabIncluded } = useSupportState();

  const tableColumns: Array<TableColumn<PartnerIncidentType>> = [
    {
      dataField: 'priority',
      text: 'Priority',
      maxWidth: 170,
      minWidth: 170,
      sort: true,
      filter: {
        type: PaginatedFilterTypeEnum.Select,
        options: PRIORITY_OPTIONS,
      },
      formatter: (_, { priority }) => {
        return <PriorityIcon priorityLevel={priority} iconSize={24} />;
      },
    },
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      isSticky: true,
      filter: { type: TableFilterTypeEnum.Text },
      formatter: (_, { title, id }) => (
        <Anchor to={`/incidents/${id}`} component={Link}>
          {title}
        </Anchor>
      ),
    },
    {
      dataField: 'device_name',
      text: 'Device',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
    },
    {
      dataField: 'device_model',
      text: 'Model',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      formatter: (_, { device_model_name }) => device_model_name,
    },
    {
      dataField: 'organization_display_name',
      text: 'Vendor',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      formatter: (_, { org_display_name, org_logo_url }) => (
        <AvatarCell label={org_display_name} src={org_logo_url} />
      ),
    },
    {
      dataField: 'space_tree_path_name',
      text: 'Space',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      formatter: spaceFormatter,
    },
    {
      dataField: 'issue',
      text: 'Trigger',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      formatter: (_, { issue }) => {
        const isRule = startsWith('rule_', issue);

        if (isRule && isChannelPartner) {
          return 'Rule';
        }

        if (isRule) {
          return <Link to={`/rules/${replace('rule_', '', issue)}`}>Rule</Link>;
        }

        if (issue === 'user') {
          return 'Manual';
        }

        return capitalize(issue);
      },
    },
    {
      dataField: 'created_at',
      text: 'Created',
      sort: true,
      filter: { type: TableFilterTypeEnum.Date },
      formatter: (cell, { created_at }) => <DateCell date={created_at} />,
    },
  ];

  return (
    <DashboardContentLayout pageTitle="Incidents">
      <PaginatedTable<PartnerIncidentType>
        keyField="id"
        name="partners.incidents"
        dataHook={usePaginatedIncidents}
        dataHookUrl={`${INCIDENTS_API_URL}/?q[organization_lab_eq]=${isLabIncluded}`}
        columns={tableColumns}
        noDataIndication={{ title: 'No Incidents' }}
        defaultSortBy={[{ id: 'priority', desc: false }]}
        additionalActions={() => <IncludeLabCustomersToggle />}
        detailsPanel={{
          type: 'page',
          renderer: ({ row, onClose }) => (
            <IncidentDetailsPanel
              incident={row.original}
              onClosePanel={onClose}
            />
          ),
        }}
      />
    </DashboardContentLayout>
  );
}

export default IncidentsList;
