import { Box, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { OrderType, PartnerOrderItemType } from '@portals/api/partners';

import { OrderItemDetails } from './OrderItemDetails';

interface StoreListingsListProps {
  order: OrderType | null;
}

export function OrderItemsList({ order }: StoreListingsListProps) {
  const { classes } = useStyles();

  const getShareableCode = (storeListing: PartnerOrderItemType) => {
    const product = (order?.purchased_products || []).find(
      (product) => product.order_item_id === storeListing.id
    );

    return product?.license.shareable_token || '';
  };

  return (
    <Stack className={classes.container}>
      <Text size="md" fw={500}>
        Products{' '}
        <Text color="gray.5" span>
          ({order?.items.length})
        </Text>
      </Text>

      {order?.items.map((storeListing) => (
        <Box key={storeListing.id} className={classes.productContainer}>
          <OrderItemDetails
            item={storeListing}
            currency={storeListing.currency}
            shareableCode={getShareableCode(storeListing)}
          />
        </Box>
      ))}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    padding: theme.spacing.xl,
    background: theme.white,
    height: 'min-content',
  },

  productContainer: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  },
}));
