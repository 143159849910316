import { Button, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { JsonEditor } from '@portals/ui';
import { isJsonString } from '@portals/utils';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getApiDevice, setConfig } from '../../redux/actions/api';

const CONFIG_BASE = `{
  "version": 1
}`;

const SetConfig = ({ access, setConfig }) => {
  const [config, setNewConfig] = useState(CONFIG_BASE);
  const validConfig = isJsonString(config);

  return (
    <Stack>
      <JsonEditor onChange={setNewConfig} data={config} />

      {!validConfig && <Text color="red">Invalid json</Text>}

      <Group>
        <Button
          disabled={!access.id || !validConfig}
          onClick={() => setConfig(access.id, JSON.parse(config))}
        >
          Send
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id && validConfig && (
        <TestCommand url={`/${access.id}/config`} data={JSON.parse(config)} />
      )}

      <Response type="setConfig" />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { setConfig })(SetConfig);
