import { Badge, Group, LoadingOverlay } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useMemo } from 'react';

import {
  CustomerWithControlledType,
  useConfig,
  useIsChannelPartner,
  useSupportCenterCustomers,
} from '@portals/api/partners';
import { useCurrentUser } from '@portals/api/ui';
import { ModalButton } from '@portals/framework';
import { RouteModalLink } from '@portals/framework/route-modals';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Ticket } from '@portals/icons/linear/ticket.svg';
import {
  AvatarCell,
  BooleanCell,
  SmartTable,
  TextWithIconCell,
} from '@portals/table';
import { PaginatedFilterTypeEnum, TableColumn } from '@portals/types';

import { BrandsCell } from './BrandsCell';
import { IncludeLabCustomersToggle } from '../../components/IncludeLabCustomersToggle';
import { RedirectToCustomerPortalButton } from '../../components/RedirectToCustomerPortalButton';
import { useUsersWithSupportSeats } from '../settings/support/support.hooks';
import { useSupportState } from '../support-overview/SupportStateProvider';

export function OrganizationsTable() {
  const { isLabIncluded } = useSupportState();
  const customers = useSupportCenterCustomers(isLabIncluded);
  const config = useConfig();

  const members = useUsersWithSupportSeats();

  const currentUser = useCurrentUser();
  const controlled = currentUser?.data?.accessible_tenants?.organizations;

  const isChannelPartner = useIsChannelPartner();

  const customersWithControlled = useMemo(
    () =>
      customers.data?.map((customer) => ({
        ...customer,
        controlled: find({ id: customer.id }, controlled) !== undefined,
      })),
    [customers, controlled]
  );

  const columns = useMemo(() => {
    const tableColumns: Array<TableColumn<CustomerWithControlledType>> = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        isSticky: true,
        minWidth: 500,
        maxWidth: 500,
        filter: {
          type: PaginatedFilterTypeEnum.Text,
        },
        formatter: (_, { name, id, logo_url, lab }) => (
          <Group align="center" noWrap>
            <RouteModalLink modalId="customer" pathParams={[id]}>
              <AvatarCell src={logo_url} label={name} radius="md" />
            </RouteModalLink>

            {lab ? (
              <Badge
                color="indigo_accent"
                variant="filled"
                size="lg"
                fw={300}
                sx={{ flexShrink: 0 }}
              >
                Lab account
              </Badge>
            ) : null}
          </Group>
        ),
      },
      {
        dataField: 'devices_count',
        text: 'Devices',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Number,
        },
        formatter: (_, row) => (
          <TextWithIconCell
            Icon={MonitorMobbile}
            label={row.devices_count.toString()}
            spacing="xs"
            color="blue_gray"
          />
        ),
      },
      {
        dataField: 'incidents_count',
        text: 'Incidents',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Number,
        },
        formatter: (_, row) => (
          <TextWithIconCell
            Icon={Danger}
            label={row.incidents_count.toString()}
            spacing="xs"
            color="blue_gray"
          />
        ),
      },
      {
        dataField: 'tickets_count',
        text: 'Tickets',
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Number,
        },
        formatter: (_, row) => (
          <TextWithIconCell
            Icon={Ticket}
            label={row.tickets_count.toString()}
            spacing="xs"
            color="blue_gray"
          />
        ),
      },
    ];

    if (isChannelPartner) {
      tableColumns.push({
        dataField: 'is_cec',
        text: 'Brands',
        minWidth: 200,
        formatter: (_, row) => <BrandsCell organization={row} />,
      });
    }

    tableColumns.push({
      dataField: 'controlled',
      text: 'Access',
      sort: true,
      formatter: (_, row) => {
        if (!row.controlled) {
          return <BooleanCell isActive={false} label="No access" />;
        }

        return (
          <RedirectToCustomerPortalButton customerId={row.id}>
            Visit portal
          </RedirectToCustomerPortalButton>
        );
      },
    });

    return tableColumns;
  }, [isChannelPartner]);

  const withAddCustomer =
    config.data?.partner?.classification === 'channel_partner' &&
    members?.length > 0;

  return (
    <>
      <LoadingOverlay visible={!customers.isFetched} />

      {customers.isFetched ? (
        <SmartTable<CustomerWithControlledType>
          keyField="id"
          name="partners.customers"
          data={customersWithControlled}
          columns={columns}
          additionalActions={() => (
            <>
              <IncludeLabCustomersToggle />

              {withAddCustomer ? (
                <ModalButton
                  modalName="AddOrganization"
                  label="Create Customer"
                  data-testid="create-customer-button"
                  leftIcon={<AddSquare />}
                />
              ) : null}
            </>
          )}
          isUrlSyncEnabled={false}
          noDataIndication={{ title: 'No customers' }}
        />
      ) : null}
    </>
  );
}
