import { Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { CurrencyCode } from '@portals/types';

import { EditableInfoCard } from './EditableInfoCard';
import { SummeryCard } from './SummeryCard';

interface OrderGeneralInfoProps {
  currency: CurrencyCode;
  itemsTotalPrice: number;
  referenceId: string | undefined;
  setReferenceId: (orderReferenceId: string) => void;
  notes: string | undefined;
  setNotes: (notes: string) => void;
  attachmentUrl: string | undefined;
  setAttachmentUrl: (attachmentUrl: string) => void;
}

export function OrderGeneralInfo({
  currency,
  itemsTotalPrice,
  referenceId,
  setReferenceId,
  notes,
  setNotes,
  setAttachmentUrl,
  attachmentUrl,
}: OrderGeneralInfoProps) {
  return (
    <Stack>
      <SummeryCard currency={currency} itemsTotalPrice={itemsTotalPrice} />

      <Paper withBorder p="xl" radius="md">
        <Stack spacing="xs">
          <Text size="md" fw={500}>
            Payment method
          </Text>
          <Text
            fw={500}
            color="blue_gray.9"
            data-testid="payment-method-option"
          >
            Offline payment
          </Text>
        </Stack>
      </Paper>

      <EditableInfoCard
        referenceId={referenceId}
        setReferenceId={setReferenceId}
        notes={notes}
        setNotes={setNotes}
        attachmentUrl={attachmentUrl}
        setAttachmentUrl={setAttachmentUrl}
      />
    </Stack>
  );
}
