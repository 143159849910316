import { Button, ButtonProps, createStyles } from '@mantine/core';
import React from 'react';

import { useRedirectToCustomerPortal } from '../hooks/customers.hooks';

type RedirectToCustomerPortalButtonProps = ButtonProps &
  React.ComponentPropsWithoutRef<'button'> & {
    customerId: string;
    afterAuthRedirectPathname?: `/${string}`;
  };

export function RedirectToCustomerPortalButton({
  customerId,
  afterAuthRedirectPathname,
  ...buttonProps
}: RedirectToCustomerPortalButtonProps) {
  const { classes } = useStyles();
  const redirectToCustomerPortal = useRedirectToCustomerPortal();

  return (
    <Button
      variant="white"
      loaderPosition="center"
      data-testid="visit-portal-button"
      className={classes.visitPortalButton}
      onClick={() =>
        redirectToCustomerPortal.redirect({
          customerId,
          afterAuthRedirectPathname,
        })
      }
      loading={
        redirectToCustomerPortal.isLoading &&
        redirectToCustomerPortal.customerIdParam === customerId
      }
      {...buttonProps}
    />
  );
}

const useStyles = createStyles(() => ({
  visitPortalButton: {
    paddingInline: 0,
    backgroundColor: 'inherit',

    '&:hover': {
      backgroundColor: 'inherit',
      textDecoration: 'underline',
    },
  },
}));
