import {
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  Switch,
  Text,
} from '@mantine/core';
import React from 'react';

import { useConfig, useUpdateConfig } from '@portals/api/partners';

export function FeaturesPanel() {
  const config = useConfig();
  const updateConfig = useUpdateConfig();

  const onChange = async (
    configField:
      | 'organization_self_managed_warranties_enabled'
      | 'device_monitoring_enabled'
      | 'organization_signup_enabled',
    isEnabled: boolean
  ) => {
    try {
      await updateConfig.mutateAsync({
        updatedConfig: {
          [configField]: isEnabled,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper p="xl" radius="md" pos="relative">
      <LoadingOverlay visible={updateConfig.isLoading} />

      <Group position="apart">
        <Group w="80%">
          <Text size="md" color="gray.9">
            Self managed warranties
          </Text>

          <Text size="sm" color="gray.5">
            Allow customers to manage warranty life of devices in their portal
          </Text>
        </Group>

        <Switch
          data-testid="self-managed-warranties-switch"
          checked={
            config.data?.portal_capabilities
              .organization_self_managed_warranties
          }
          onChange={(event) =>
            onChange(
              'organization_self_managed_warranties_enabled',
              event.target.checked
            )
          }
        />
      </Group>

      <Divider my="md" />

      <Group position="apart">
        <Group w="80%">
          <Text size="md" color="gray.9">
            Device Management
          </Text>

          <Text size="sm" color="gray.5">
            Grant your end-users the ability to create their custom workplace
            tree and access an inventory of all their connected devices
          </Text>
        </Group>

        <Switch
          data-testid="device-management-switch"
          checked={config.data?.portal_capabilities.device_monitoring}
          onChange={(event) =>
            onChange('device_monitoring_enabled', event.target.checked)
          }
        />
      </Group>

      <Divider my="md" />

      <Group position="apart">
        <Group w="80%">
          <Text size="md" color="gray.9" style={{ flexBasis: '100%' }}>
            Customer self sign-up / connect
          </Text>

          <Text size="sm" color="gray.5">
            Allow end customers to sign up or connect without an invite.
            <br />
            When turned off, customers must be invited by the OEM
          </Text>
        </Group>

        <Switch
          data-testid="customer-self-sign-up-switch"
          checked={config.data?.portal_capabilities.organization_signup}
          onChange={(event) =>
            onChange('organization_signup_enabled', event.target.checked)
          }
        />
      </Group>
    </Paper>
  );
}
