import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  ORGANIZATIONS_API_URL,
  organizationsQueryKeys,
} from './organizations.constants';
import { OrganizationType } from './organizations.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { uiGlobalQueryKeys } from '../../ui/global-query-keys';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { monitoringQueryKeys } from '../monitoring/monitoring.constants';

export function useOrganizations() {
  return useApiQuery<OrganizationType[]>(
    ORGANIZATIONS_API_URL,
    organizationsQueryKeys.all
  );
}

export interface UseCreateOrganizationParams {
  organization_name: string;
  name: string;
  email: string;
  main_partner_id?: string;
  auto_admin?: boolean;
}

export function useCreateOrganization() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: ORGANIZATIONS_API_URL,
    method: 'POST',
  });

  return useMutation<
    { cec_customer_exists?: true },
    ServerError,
    UseCreateOrganizationParams
  >({
    mutationFn: (params) =>
      fetchApiRequest(url, { ...options, body: JSON.stringify(params) }),
    onSuccess: (response) => {
      if (response.cec_customer_exists) return;

      dispatch(toastrSuccess('Organization created successfully'));

      queryClient.invalidateQueries(organizationsQueryKeys.all);

      queryClient.invalidateQueries(monitoringQueryKeys.customers.all());

      queryClient.invalidateQueries(uiGlobalQueryKeys.users);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateOrganization',
      baseUrl: ORGANIZATIONS_API_URL,
      method: 'POST',
    },
  });
}

interface UseEditCustomerDetailsParams {
  channel: string;
  referrer_id: string;
  customerId: string;
}

export function useEditCustomerDetails() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: ORGANIZATIONS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseEditCustomerDetailsParams>({
    mutationFn: ({ channel, referrer_id, customerId }) =>
      fetchApiRequest(`${url}/${customerId}/referrer`, {
        ...options,
        body: JSON.stringify({
          referrer_id,
          channel,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(organizationsQueryKeys.all);

      queryClient.invalidateQueries(monitoringQueryKeys.customers.all());

      queryClient.invalidateQueries(uiGlobalQueryKeys.users);

      dispatch(toastrSuccess('Your changes have been saved successfully'));
    },
    onError: ({ error }) => {
      dispatch(toastrError(error || 'Could not save changes'));
    },
    meta: {
      mutationName: 'useEditCustomerDetails',
      baseUrl: ORGANIZATIONS_API_URL,
      method: 'POST',
    },
  });
}
