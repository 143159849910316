import { Button, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { JsonEditor } from '@portals/ui';
import { isJsonString } from '@portals/utils';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { getApiDevice, updateLicense } from '../../redux/actions/api';

const DATA_BASE = `{
  "id": "",
  "state": "inuse"
}`;

const UpdateLicense = ({ access, updateLicense }) => {
  const [data, setData] = useState(DATA_BASE);
  const validData = isJsonString(data);

  return (
    <Stack>
      <JsonEditor onChange={setData} data={data} size={10} />

      {!validData && <Text color="red">Invalid json</Text>}

      <Group>
        <Button
          disabled={!access.id || !validData}
          onClick={() => updateLicense(access.id, JSON.parse(data))}
        >
          Send
        </Button>
        <span>
          Device ID:
          {access.id || 'Not set'}
        </span>
      </Group>

      {access.id && validData && (
        <TestCommand url={`/${access.id}/licenses`} data={JSON.parse(data)} />
      )}
      <Response type="updateLicense" />
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  access: getApiDevice(state),
});

export default connect(mapStateToProps, { updateLicense })(UpdateLicense);
