import { Group, Text } from '@mantine/core';
import React from 'react';

import { CustomerWithControlledType } from '@portals/api/partners';
import { PartnerAvatar } from '@portals/framework';

import { CecPartnersAndIntegrationsList } from './CecPartnersAndIntegrationsList';
import { ReactComponent as ConnectIcon } from '../../assets/img/connect-logo.svg';

interface BrandsCellProps {
  organization: CustomerWithControlledType;
}

export function BrandsCell({ organization }: BrandsCellProps) {
  const getBrandsCount = () => {
    if (organization.cec_approved_partner_count === 1) {
      return '1 Brand';
    } else {
      return `${organization.cec_approved_partner_count} Brands`;
    }
  };

  if (!organization.is_cec) {
    return (
      <Group spacing="sm">
        <PartnerAvatar
          src={organization.partner.logo_url}
          partnerName={organization.partner.display_name}
          size={40}
        />

        <Text color="gray.7">{organization.partner.display_name}</Text>
      </Group>
    );
  }

  return (
    <Group spacing="sm" noWrap>
      <ConnectIcon />

      <Group spacing="xs">
        <Text color="gray.7">{getBrandsCount()}</Text>
        <CecPartnersAndIntegrationsList organizationId={organization.id} />
      </Group>
    </Group>
  );
}
