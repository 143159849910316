import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import {
  useFeatureFlags,
  useIsChannelPartner,
  usePortalCapabilities,
} from '@portals/api/partners';
import {
  IntegrationsRouteModal,
  Page404,
  useHasSupportSeat,
  usePermissionAccess,
} from '@portals/framework';
import { PartnerFeatureFlagEnum } from '@portals/types';

import { DASHBOARD_PATHS, StaticPath } from './dashboard-paths.constants';
import { usePricingPlanFeatures } from '../../hooks/partner-config';
import { Branding } from '../../pages/branding/Branding';
import { CreditProviders } from '../../pages/credit-providers';
import { Customers } from '../../pages/customers';
import Dashboard from '../../pages/dashboard';
import { DevCenter } from '../../pages/dev-center';
import { Devices } from '../../pages/devices/Devices';
import { Files } from '../../pages/files';
import { Home } from '../../pages/home';
import Incidents from '../../pages/incidents';
import Models from '../../pages/models';
import { Organizations } from '../../pages/organizations';
import { Partners } from '../../pages/partners/Partners';
import { ProductsCatalogPage } from '../../pages/products-catalog/ProductsCatalogPage';
import Rules from '../../pages/rules';
import { Settings } from '../../pages/settings/Settings';
import { CreateTaxGroup } from '../../pages/settings/store-settings/tax-groups/create-tax-group';
import { StoreListingsPage } from '../../pages/store-listings/StoreListingsPage';
import { Invoices } from '../../pages/store-management/pages/invoices/Invoices';
import { Orders } from '../../pages/store-management/pages/orders/Orders';
import { PurchasedProducts } from '../../pages/store-management/pages/purchased-products/PurchasedProducts';
import { Shipping } from '../../pages/store-management/pages/shipping/Shipping';
import { Subscriptions } from '../../pages/store-management/pages/subscriptions/Subscriptions';
import { StoreManagement } from '../../pages/store-management/StoreManagement';
import { SupportOverview } from '../../pages/support-overview';
import { Tickets } from '../../pages/tickets';
import { CreditProviders as CreditProvidersRouteModal } from '../../route-modals/CreditProviders';
import { Customer } from '../../route-modals/Customer';
import { OfflineOrder } from '../../route-modals/offline-order/OfflineOrder';
import { Order } from '../../route-modals/Order/Order';
import { PurchasedProductRoutePanel } from '../../route-panels/product/PurchasedProductRoutePanel';
import { StoreListingRoutePanel } from '../../route-panels/store-listings/StoreListingRoutePanel';
import { SubscriptionRoutePanel } from '../../route-panels/subscriptions/SubscriptionRoutePanel';

interface DashboardRoutesContextType {
  routes: Array<RouteObject>;
  canAccessPath: ReturnType<typeof useCanAccessPath>['canAccessPath'];
}

const DashboardRoutesContext = createContext<DashboardRoutesContextType | null>(
  null
);

export function DashboardRoutesProvider({ children }: { children: ReactNode }) {
  const { canAccessPath } = useCanAccessPath();

  const routes = useMemo(() => {
    const allRoutes: Array<RouteObject> = [
      {
        path: DASHBOARD_PATHS.staticPaths.root,
        element: <RootRoute />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.getStarted,
        element: <Home />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.dashboardOverview,
        element: <Dashboard />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.partners,
        element: <Partners />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.customers,
        element: <Customers />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.deviceModels,
        element: <Models />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.deviceModel,
        element: <Models />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.devices,
        element: <Devices />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.files,
        element: <Files />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.file,
        element: <Files />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.devCenter,
        element: <DevCenter />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.rule,
        element: <Rules />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.productCatalog,
        element: <ProductsCatalogPage />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.storeListings,
        element: <StoreListingsPage />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.storeManagement,
        element: <StoreManagement />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.orders,
        element: <Orders />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.subscriptions,
        element: <Subscriptions />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.soldProducts,
        element: <PurchasedProducts />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.shipping,
        element: <Shipping />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.creditProviders,
        element: <CreditProviders />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.invoices,
        element: <Invoices />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.financeOrders,
        element: <Orders />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.supportOverview,
        element: <SupportOverview />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.tickets,
        element: <Tickets />,
        children: [
          {
            path: DASHBOARD_PATHS.staticPaths.ticketsAll,
            element: <Tickets />,
          },
          {
            path: DASHBOARD_PATHS.staticPaths.ticketsActive,
            element: <Tickets />,
          },
          {
            path: DASHBOARD_PATHS.staticPaths.ticket,
            element: <Tickets />,
          },
        ],
      },
      {
        path: DASHBOARD_PATHS.staticPaths.incidents,
        element: <Incidents />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.supportedCustomers,
        element: <Organizations />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.branding,
        element: <Branding />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.settings,
        element: <Settings />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.settingsCreateTaxGroup,
        element: <CreateTaxGroup />,
      },

      // Route Modals
      {
        path: DASHBOARD_PATHS.staticPaths.customerRouteModal,
        element: <Customer />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.orderRouteModal,
        element: <Order />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.offlineOrderRouteModal,
        element: <OfflineOrder />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.creditProvidersRouteModal,
        element: <CreditProvidersRouteModal />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.integrationsRouteModal,
        element: <IntegrationsRouteModal />,
      },

      // Route Panels
      {
        path: DASHBOARD_PATHS.staticPaths.storeListingRoutePanel,
        element: <StoreListingRoutePanel />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.subscriptionRoutePanel,
        element: <SubscriptionRoutePanel />,
      },
      {
        path: DASHBOARD_PATHS.staticPaths.purchasedProductRoutePanel,
        element: <PurchasedProductRoutePanel />,
      },
    ];

    return allRoutes
      .filter((route) => canAccessPath(route.path as StaticPath))
      .concat({ path: '*', element: <Page404 /> });
  }, [canAccessPath]);

  return (
    <DashboardRoutesContext.Provider value={{ routes, canAccessPath }}>
      {children}
    </DashboardRoutesContext.Provider>
  );
}

function RootRoute() {
  const { canAccessPath } = useDashboardRoutes();
  const pricingPlanFeatures = usePricingPlanFeatures();

  if (pricingPlanFeatures?.device_models_limit === 0) {
    return (
      <Navigate to={DASHBOARD_PATHS.staticPaths.supportOverview} replace />
    );
  }

  if (canAccessPath(DASHBOARD_PATHS.staticPaths.getStarted)) {
    return <Navigate to={DASHBOARD_PATHS.staticPaths.getStarted} replace />;
  }

  return (
    <Navigate to={DASHBOARD_PATHS.staticPaths.dashboardOverview} replace />
  );
}

export function useDashboardRoutes() {
  const context = useContext(DashboardRoutesContext);

  if (!context) {
    throw new Error(
      'useDashboardRoutes must be used within a DashboardRoutesProvider'
    );
  }

  return context;
}

type PathAccessRules = Partial<
  Record<
    StaticPath,
    {
      dependencies?: Array<StaticPath>;
      isAccessible?: boolean | (() => boolean);
    }
  >
>;

function useCanAccessPath() {
  const featureFlags = useFeatureFlags();
  const pricingPlanFeatures = usePricingPlanFeatures();
  const portalCapabilities = usePortalCapabilities();
  const hasSupportSeat = useHasSupportSeat();
  const isChannelPartner = useIsChannelPartner();
  const { isAdmin, canView } = usePermissionAccess();

  const pathsAccessCache = useRef<Partial<Record<StaticPath, boolean>>>({});

  const pathAccessRules = useMemo<PathAccessRules>(() => {
    // Reset the paths access cache when this hook is re-executed
    pathsAccessCache.current = {};

    return {
      // =============== Home Hub ===============
      [DASHBOARD_PATHS.staticPaths.getStarted]: {
        isAccessible: () => {
          if (pricingPlanFeatures?.device_models_limit === 0) {
            return false;
          }

          return isAdmin && featureFlags?.home === PartnerFeatureFlagEnum.On;
        },
      },
      [DASHBOARD_PATHS.staticPaths.dashboardOverview]: {
        isAccessible: () => {
          if (pricingPlanFeatures?.device_models_limit === 0) {
            return false;
          }

          return true;
        },
      },
      [DASHBOARD_PATHS.staticPaths.partners]: {
        isAccessible: isAdmin,
      },
      [DASHBOARD_PATHS.staticPaths.customers]: {
        isAccessible: !isChannelPartner,
      },
      [DASHBOARD_PATHS.staticPaths.settings]: {
        isAccessible: isAdmin,
      },
      [DASHBOARD_PATHS.staticPaths.settingsCreateTaxGroup]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.settings],
      },

      // =============== Product Hub ===============
      [DASHBOARD_PATHS.staticPaths.deviceModels]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.productHub],
        isAccessible: () => {
          if (pricingPlanFeatures?.device_models_limit === 0) {
            return false;
          }

          return hasSupportSeat || canView(['models', 'store_management']);
        },
      },
      [DASHBOARD_PATHS.staticPaths.deviceModel]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.deviceModels],
      },
      [DASHBOARD_PATHS.staticPaths.devices]: {
        isAccessible: () => {
          if (pricingPlanFeatures?.device_models_limit === 0) {
            return false;
          }

          return hasSupportSeat || canView(['models', 'fulfillment']);
        },
      },
      [DASHBOARD_PATHS.staticPaths.files]: {
        isAccessible: () => {
          if (pricingPlanFeatures?.device_models_limit === 0) {
            return false;
          }

          return hasSupportSeat || canView('models');
        },
      },
      [DASHBOARD_PATHS.staticPaths.file]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.files],
      },
      [DASHBOARD_PATHS.staticPaths.rule]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.deviceModels],
      },
      [DASHBOARD_PATHS.staticPaths.devCenter]: {
        isAccessible: () => {
          if (pricingPlanFeatures?.device_models_limit === 0) {
            return false;
          }

          return hasSupportSeat || canView('models');
        },
      },

      // =============== eCommerce Hub ===============
      [DASHBOARD_PATHS.staticPaths.productCatalog]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.ecommerceHub],
        isAccessible: () => {
          if (isChannelPartner) {
            return false;
          }

          return hasSupportSeat || canView('store_management');
        },
      },
      [DASHBOARD_PATHS.staticPaths.storeListings]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.ecommerceHub],
        isAccessible:
          pricingPlanFeatures?.store === 1 &&
          (hasSupportSeat || canView('store_management')),
      },
      [DASHBOARD_PATHS.staticPaths.storeManagement]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.ecommerceHub],
        isAccessible: pricingPlanFeatures?.store === 1,
      },
      [DASHBOARD_PATHS.staticPaths.orders]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.storeManagement],
        isAccessible: canView(['finance', 'fulfillment']),
      },
      [DASHBOARD_PATHS.staticPaths.subscriptions]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.storeManagement],
        isAccessible: canView(['store_management', 'finance', 'fulfillment']),
      },
      [DASHBOARD_PATHS.staticPaths.soldProducts]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.storeManagement],
        isAccessible: canView(['store_management', 'finance', 'fulfillment']),
      },

      // =============== Operations Hub ===============
      [DASHBOARD_PATHS.staticPaths.shipping]: {
        isAccessible:
          pricingPlanFeatures?.store === 1 &&
          canView(['finance', 'fulfillment']),
      },

      // =============== Finance Hub ===============
      [DASHBOARD_PATHS.staticPaths.financeHub]: {
        isAccessible: canView('finance'),
      },
      [DASHBOARD_PATHS.staticPaths.creditProviders]: {
        isAccessible:
          isAdmin &&
          featureFlags?.credit_providers === PartnerFeatureFlagEnum.On &&
          (hasSupportSeat || canView(['finance', 'customers', 'fulfillment'])),
      },
      [DASHBOARD_PATHS.staticPaths.invoices]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.financeHub],
        isAccessible: pricingPlanFeatures?.store === 1,
      },
      [DASHBOARD_PATHS.staticPaths.financeOrders]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.financeHub],
        isAccessible: pricingPlanFeatures?.store === 1,
      },

      // =============== Support Hub ===============
      [DASHBOARD_PATHS.staticPaths.supportHub]: {
        isAccessible:
          isAdmin || (hasSupportSeat && portalCapabilities?.support_center),
      },
      [DASHBOARD_PATHS.staticPaths.supportOverview]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.supportHub],
      },
      [DASHBOARD_PATHS.staticPaths.supportedCustomers]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.supportHub],
      },
      [DASHBOARD_PATHS.staticPaths.tickets]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.supportHub],
        isAccessible: pricingPlanFeatures?.device_models_limit !== 0,
      },
      [DASHBOARD_PATHS.staticPaths.incidents]: {
        dependencies: [DASHBOARD_PATHS.staticPaths.supportHub],
      },

      // =============== Marketing Hub ===============
      [DASHBOARD_PATHS.staticPaths.branding]: {
        isAccessible: isAdmin && !isChannelPartner,
      },
    };
  }, [
    canView,
    featureFlags?.credit_providers,
    featureFlags?.home,
    hasSupportSeat,
    isAdmin,
    isChannelPartner,
    portalCapabilities?.support_center,
    pricingPlanFeatures?.device_models_limit,
    pricingPlanFeatures?.store,
  ]);

  const isPathAccessible = useCallback(
    (path: StaticPath): boolean => {
      const accessRule = pathAccessRules[path];

      // If the path has no access rules, it means its fully accessible;
      if (!accessRule) return true;

      const { isAccessible } = accessRule;

      if (typeof isAccessible === 'boolean') return isAccessible;
      if (typeof isAccessible === 'function') return isAccessible();

      // If the `isAccessible` is not defined, the path is accessible
      return true;
    },
    [pathAccessRules]
  );

  const checkDependencies = useCallback(
    (path: StaticPath): boolean => {
      // If the path has already been checked, return the cached result
      if (pathsAccessCache.current[path] !== undefined) {
        return pathsAccessCache.current[path] as boolean;
      }

      const accessRule = pathAccessRules[path];

      let result;

      // If there's no access rules and/or dependencies, check the path accessibility directly
      if (!accessRule?.dependencies?.length) {
        result = isPathAccessible(path);
      } else {
        // Check dependencies recursively
        const areDependenciesMet =
          accessRule.dependencies.every(checkDependencies);

        // If all dependencies are met, check the path accessibility
        if (areDependenciesMet) {
          result = isPathAccessible(path);
        } else {
          // If any dependency is not met, return false
          result = false;
        }
      }

      pathsAccessCache.current[path] = result;
      return result;
    },
    [isPathAccessible, pathAccessRules]
  );

  const canAccessPath = useCallback(
    (path: StaticPath): boolean => {
      // If the path has already been checked, return the cached result
      if (pathsAccessCache.current[path] !== undefined) {
        return pathsAccessCache.current[path] as boolean;
      }

      // First check if the path is accessible, if not there's no need to check its dependencies
      if (!isPathAccessible(path)) {
        pathsAccessCache.current[path] = false;
        return false;
      } else {
        // Recursively check dependencies
        return checkDependencies(path);
      }
    },
    [checkDependencies, isPathAccessible]
  );

  return {
    canAccessPath,
  };
}
