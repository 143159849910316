import { createStyles, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { map } from 'lodash/fp';
import React from 'react';

import { TaxGroupType } from '@portals/api/partners';

import { TAX_GROUPS_ICONS } from '../../../../components/tax-groups/tax-groups-icons';

interface TaxGroupsListProps {
  groups: Array<TaxGroupType>;
}

export function TaxGroupsList({ groups }: TaxGroupsListProps) {
  const { classes } = useStyles();

  return (
    <Stack>
      {map((group) => {
        return (
          <Group
            key={group.name}
            spacing="sm"
            py="xl"
            className={classes.container}
            position="apart"
          >
            <Group align="center">
              <ThemeIcon
                variant="light"
                radius="md"
                color={TAX_GROUPS_ICONS[group.icon_name]?.color}
                size={40}
              >
                {TAX_GROUPS_ICONS[group.icon_name]?.component}
              </ThemeIcon>

              <Group spacing="xs">
                <Text
                  size="sm"
                  sx={(theme) => ({ color: theme.colors.blue_gray[8] })}
                >
                  {group.name}
                </Text>
                <Text
                  size="sm"
                  sx={(theme) => ({ color: theme.colors.blue_gray[4] })}
                >
                  ({group.products_count} Products)
                </Text>
              </Group>
            </Group>

            <Text size="xs" color="blue_gray.3">
              Tax group {group.tax_code}
            </Text>
          </Group>
        );
      }, groups)}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
  },
}));
