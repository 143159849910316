import { Box, createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { useIsChannelPartner } from '@portals/api/partners';
import { useCurrentUser } from '@portals/api/ui';
import { DashboardContentLayout, useHasSupportSeat } from '@portals/framework';

import { CustomersView } from './CustomersView';
import { KpiWidgets } from './KpiWidgets';
import { SupportCenter } from './SupportCenter';
import { SupportStateProvider } from './SupportStateProvider';
import { IncludeLabCustomersToggle } from '../../components/IncludeLabCustomersToggle';
import { ChannelPartnerWelcomeVideosTour } from '../home/ChannelPartnerWelcomeVideosTour';

export function SupportOverview() {
  const { classes, theme } = useStyles();
  const hasSupportSeat = useHasSupportSeat();
  const isChannelPartner = useIsChannelPartner();
  const currentUser = useCurrentUser();

  return (
    <SupportStateProvider>
      {isChannelPartner &&
        !currentUser.data?.settings.is_welcome_videos_played && (
          <ChannelPartnerWelcomeVideosTour />
        )}
      <DashboardContentLayout
        pageTitle={
          <Group position="apart">
            <Text inherit>Support Overview</Text>
            {hasSupportSeat ? <IncludeLabCustomersToggle /> : null}
          </Group>
        }
        sx={{ backgroundColor: theme.colors.gray[0] }}
      >
        <Stack className={classes.container} spacing="md">
          <KpiWidgets />

          <Box className={classes.mainView}>
            <SupportCenter />

            <CustomersView />
          </Box>
        </Stack>
      </DashboardContentLayout>
    </SupportStateProvider>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    [theme.fn.largerThan('xl')]: {
      gap: theme.spacing.xl,
    },
  },
  mainView: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: theme.spacing.md,

    [theme.fn.largerThan('xl')]: {
      gap: theme.spacing.xl,
    },
  },
  mainViewItem: {
    border: `1px solid ${theme.colors.gray[2]}`,
    maxWidth: '100%',
  },
}));
