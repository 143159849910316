import {
  Badge,
  Group,
  LoadingOverlay,
  Switch,
  SwitchProps,
} from '@mantine/core';
import React, { useMemo, useState } from 'react';

import { useCurrentUser, UserResponseType, useUsers } from '@portals/api/ui';
import { OverflowBadgeGroup } from '@portals/core';
import { ModalButton, usePermissionAccess } from '@portals/framework';
import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as LifebuoyIcon } from '@portals/icons/linear/lifebuoy.svg';
import { useOpenModal } from '@portals/redux';
import {
  AvatarCell,
  DateCell,
  RowMenuItem,
  RowMenuItems,
  SmartTable,
} from '@portals/table';
import { TableColumn } from '@portals/types';
import { timeAgo } from '@portals/utils';

import { UsersTableDetailsPanel } from './UsersTableDetailsPanel';
import { ToggleUserSupportSeatModalProps } from '../../../modals';
import { DeleteUserModalProps } from '../../../modals/DeleteUserModal';

export function UsersTable() {
  const users = useUsers();
  const currentUser = useCurrentUser();
  const { isAdmin } = usePermissionAccess();
  const openModal = useOpenModal();

  const [isDeletedUsersIncluded, setIsDeletedUsersIncluded] = useState(false);

  const usersList = useMemo(() => {
    if (!users.data) {
      return [];
    }

    if (isDeletedUsersIncluded) {
      return users.data;
    }

    return users.data.filter((user) => !user.deactivated_at);
  }, [users, isDeletedUsersIncluded]);

  const onDeleteUser = (user: UserResponseType) => {
    openModal<DeleteUserModalProps['data']>('DeleteUserModal', {
      user,
    });
  };

  const columns: TableColumn<UserResponseType>[] = [
    {
      dataField: 'name',
      text: 'Name',
      minWidth: 500,
      maxWidth: 500,
      isSticky: true,
      sort: true,
      formatter: (
        _,
        {
          name,
          email,
          has_support_seat,
          last_sign_in_at,
          deactivated_at,
          settings,
        }
      ) => (
        <Group align="center" spacing="xl">
          <AvatarCell
            src={settings.profile_image}
            label={last_sign_in_at ? name : email}
            description={last_sign_in_at ? email : 'Invitation sent...'}
            labelColor={deactivated_at ? 'gray.5' : undefined}
            withAbbreviation={!!last_sign_in_at}
          />

          {has_support_seat ? (
            <Badge
              bg="purple.0"
              radius="sm"
              color="gray.9"
              fw="400"
              sx={{ textTransform: 'none' }}
            >
              Support seat
            </Badge>
          ) : null}

          {deactivated_at ? (
            <Badge
              radius="lg"
              color="blue_gray"
              fw="400"
              size="lg"
              data-testid="deleted-user-badge"
            >
              Deleted user
            </Badge>
          ) : null}
        </Group>
      ),
    },
    {
      dataField: 'group_names',
      text: 'Groups',
      formatter: (_, row) => <OverflowBadgeGroup badges={row.group_names} />,
    },
    {
      dataField: 'created_at',
      text: 'Created at',
      sort: true,
      minWidth: 250,
      formatter: (_, row) => (
        <DateCell date={row.created_at} withTimeAgo={false} />
      ),
    },
    {
      dataField: 'last_seen_at',
      text: 'Last seen',
      sort: true,
      formatter: (_, { last_seen_at }) =>
        last_seen_at ? timeAgo(Date.parse(last_seen_at)) : 'never',
    },
    {
      dataField: 'sign_in_count',
      text: 'Logins count',
      sort: true,
      minWidth: 200,
    },
    {
      dataField: 'last_sign_in_at',
      text: 'Last login',
      sort: true,
      formatter: (_, { last_sign_in_at }) =>
        last_sign_in_at ? timeAgo(Date.parse(last_sign_in_at)) : 'never',
    },
    {
      dataField: 'last_ip',
      text: 'Last login IP',
      sort: true,
      minWidth: 200,
    },
  ];

  if (users.isFetching || currentUser.isFetching) {
    return <LoadingOverlay visible />;
  }

  return (
    <SmartTable<UserResponseType>
      keyField="id"
      name="partners.settings.users"
      data={usersList}
      columns={columns}
      noFilters
      exportParams={{ isEnabled: false }}
      noHeader={!isAdmin}
      noColumnsSelection
      noDataIndication={{ title: 'No users' }}
      additionalActions={() => (
        <Group spacing="xl">
          <Switch
            size="xs"
            label="Show deleted users"
            labelPosition="left"
            checked={isDeletedUsersIncluded}
            onChange={(e) => setIsDeletedUsersIncluded(e.target.checked)}
            styles={switchStyles}
          />

          {isAdmin ? (
            <ModalButton
              modalName="AddUserModal"
              label="Add User"
              size="sm"
              data-testid="partners-add-user-button"
            />
          ) : null}
        </Group>
      )}
      detailsPanel={{
        type: 'page',
        renderer: ({ row, onClose }) => (
          <UsersTableDetailsPanel
            user={row.original}
            onClose={onClose}
            onDeleteUser={onDeleteUser}
          />
        ),
      }}
      rowMenu={({ row, wrapperProps }) => {
        if (row.original.deactivated_at) return null;

        const hasSupportSeat = row.original.has_support_seat;

        const items: Array<RowMenuItem> = [
          {
            id: 'toggle-support-seat',
            label: hasSupportSeat
              ? 'Revoke Support Seat'
              : 'Assign Support Seat',
            Icon: LifebuoyIcon,
            onClick: () => {
              wrapperProps.menuRef?.onClose();
              openModal<ToggleUserSupportSeatModalProps['data']>(
                'ToggleUserSupportSeatModal',
                {
                  intent: hasSupportSeat ? 'revoke' : 'assign',
                  userId: row.original.id,
                }
              );
            },
          },
        ];

        if (
          isAdmin &&
          !row.original.deactivated_at &&
          row.original.id !== currentUser.data?.id
        ) {
          items.push({
            id: 'delete-user',
            label: 'Delete User',
            Icon: CloseCircle,
            color: 'red',
            disabled: row.original.id === currentUser.data?.id,
            onClick: () => {
              wrapperProps.menuRef?.onClose();
              onDeleteUser(row.original);
            },
          });
        }

        return <RowMenuItems wrapperProps={wrapperProps} items={items} />;
      }}
    />
  );
}

const switchStyles: SwitchProps['styles'] = (theme) => ({
  label: {
    color: theme.colors.blue_gray[9],
  },
});
